@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Oswald:wght@300&display=swap');

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Montserrat', sans-serif!important;
}

.ui.text.container, .ui.horizontal.list .list { font-family: 'Montserrat', sans-serif!important;}
.ui.button, .ui.header, .ui.card>.content>.header  { font-family: 'Oswald', sans-serif!important;}
.homebtn {border-radius:0px!important;}

@media screen and (min-width: 768px) {

.homebtn {
  width:200px;
  font-size:18px!important;
}

.habitsl {
  display: flex!important;
  justify-content: center!important;
  text-align: -webkit-center;
  flex-wrap: wrap;
} 

.ui.progress {
  text-align:left!important;
  border-radius:0px!important;
}
}

@media screen and (max-width: 768px) {
  .homebtn {
   width:48%;
    font-size:16px!important;
    padding-left:0px!important;
    padding-right:0px!important;
  }
}

.ui.card:last-child {
  margin-bottom: 10px;
}

.ui.active.progress .bar {border-radius:0px;}

.ui.card>:last-child {border-radius:0px!important;}

.ui.card {border-Radius:0px;}

  .ui.horizontal.list:not(.celled)>.item:first-child {margin-left:1em!important;}
